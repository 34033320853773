import React from "react";
import {Button, Row, Col, FormControl, ButtonGroup} from "react-bootstrap";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import RecordPlaybackModal from "./RecordPlaybackModal";
import moment from "moment";
import {faDownload, faPlay, faMinus, faTextWidth} from "@fortawesome/free-solid-svg-icons";
import DatePicker, { registerLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import * as GlobalConst from "../../components/AscConstants";
import ja from 'date-fns/locale/ja';
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";
import ReactLoading from 'react-loading';
import ButtonSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";
registerLocale('ja', ja);


export default class Record extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            pageSize: 20,
            loading: false,
            hidden_filtered: [
                {
                    id: "user_id",
                    value: this.props.userInfo.user_id
                }
            ],
            filtered: [
                {
                    id: "user_id",
                    value: this.props.userInfo.user_id
                }
            ],
            sorted: [{id: "answer_datetime", desc: true}],
            audio2text_usage_month: 0,

            // modal item
            show: false,
            modalType: null,
            companySelect: [],
            selectedData: [],
            blocking: false,
            search_flag: true,
            search_message: ""
        };


        this.getAudio2TextUsageMonth();
        this.table = React.createRef();
        this.hourShowList = this.getSelectOption('hour_show_list', this.props.langText.SelectOption);
        this.minuteShowList = this.getSelectOption('minute_show_list', this.props.langText.SelectOption);
    }
    /**
     * 一覧表作成
     */
    getColumnsData() {

        let returnArray;

        if (window.innerWidth <= 768) {
            returnArray = [
                {
                    Header: this.props.langText.Body.NumInfo,
                    accessor: "dest_tel_number",
                    width: this.props.boardWidth.small,
                    Cell: data => {
                        let display_tel = `${data.row.original.dest_tel_number}`;
                        let inout_type = "";
                        let rowData = [];
                        if (data.row.original.inout_type === "in") {
                            inout_type = this.props.langText.Body.IncomingIcon
                        } else if (data.row.original.inout_type === "out") {
                            inout_type = this.props.langText.Body.OutgoingIcon
                        }
                        if (data.row.original.hm01_customer_info) {
                            display_tel =  `${data.row.original.hm01_customer_info.name}(${data.row.original.dest_tel_number})`
                        }
                        rowData.push(<div>{data.row.original.external_number}</div>);
                        rowData.push(<div>{inout_type}</div>)
                        rowData.push(<div>{display_tel}</div>)
                        return rowData;
                    },
                }, {
                    Header: this.props.langText.Body.CallInfo,
                    accessor: "answer_datetime",
                    width: this.props.boardWidth.small,
                    Cell: data =>(
                        <>
                            <div className = "call_time">
                                {moment(data.row.original.answer_datetime).utc().add(9, "h").format("YYYY/MM/DD")}
                            </div>
                            <div className = "call_time">
                                {moment(data.row.original.answer_datetime).utc().add(9, "h").format("HH:mm:ss")}
                            </div>
                            <div className = "billsec">
                                {this.getFormatSec(data.row.original.billsec)}
                            </div>
                        </>

                    )
                }
            ];
        } else {
            returnArray = [
                {
                    Header: this.props.langText.Body.ExternalNumber,
                    accessor: "external_number",
                    width: this.props.boardWidth.CallNumber,
                }, {
                    Header: this.props.langText.Body.DestTelNumber,
                    accessor: "dest_tel_number",
                    width: this.props.boardWidth.CallNumber,
                    Cell: data => {
                        if (data.row.original.hm01_customer_info) {
                            return `${data.row.original.hm01_customer_info.name}(${data.row.original.dest_tel_number})`
                        } else {
                            return `${data.row.original.dest_tel_number}`
                        }
                    },
                }, {
                    Header: this.props.langText.Body.InOutType,
                    accessor: "inout_type",
                    width: this.props.boardWidth.xsmall,
                    Cell: data => {
                        if (data.row.original.inout_type === "in") {
                            return this.props.langText.Body.Incoming
                        } else if (data.row.original.inout_type === "out") {
                            return this.props.langText.Body.Outgoing
                        } else {
                            return "";
                        }
                    }
                }, {
                    Header: this.props.langText.Body.CallDate,
                    accessor: "answer_datetime",
                    width: this.props.boardWidth.xlarge,
                    Cell: data => moment(data.row.original.answer_datetime).utc().add(9, "h").format("YYYY/MM/DD HH:mm:ss"),
                }, {
                    Header: this.props.langText.Body.BillSec,
                    accessor: "billsec",
                    width: this.props.boardWidth.medium,
                    Cell: data => {
                        return this.getFormatSec(data.row.original.billsec);
                    }
                }
            ];
        }

        returnArray.push({
            Header: this.props.langText.Body.Control,
            accessor: "id",
            width: this.props.boardWidth.Control,
            Cell: data => {
                let rowData = [];
                if (data.row.original.s3_object_key) {
                    if (this.props.currentPermission.playback) {
                        rowData.push(
                            <ButtonSpeechBubble
                                key={data.row.original.s3_object_key + "_playback"}
                                size="sm"
                                onClick={this.modalShow("playback", data)}
                                className = "control-button"
                                DisplayIcon={faPlay}
                                speechBubble={[
                                    <div key={data.row.original.s3_object_key + "PlayBack"}>
                                        {this.props.langText.Body.PlayBack}
                                    </div>
                                ]}
                            />
                        );
                    }
                    if (this.props.currentPermission.download) {
                        rowData.push(
                            <ButtonSpeechBubble
                                key={data.row.original.s3_object_key + "_download"}
                                size="sm"
                                onClick={this.modalShow("download", data)}
                                className={"control-button"}
                                DisplayIcon={faDownload}
                                speechBubble={[
                                    <div key={data.row.original.s3_object_key + "_download_bubble"}>
                                        {this.props.langText.Body.AudioFileDownload}
                                    </div>
                                ]}
                            />
                        );
                    }

                    if ((!this.props.userInfo.options || (this.props.userInfo.options && !this.props.userInfo.options.audio2text))
                        && data.row.original.ht87_voice_log_to_text) {
                        let message;
                        switch (data.row.original.ht87_voice_log_to_text.status) {
                            case "CONVERSION_COMPLETE":
                                message = this.props.langText.Body.audio2textComplete;
                                break;
                            case "REQUEST_ACCEPTED":
                                message = this.props.langText.Body.audio2textWorking;
                                break;
                            default:
                                message = this.props.langText.Body.audio2textFailed;
                                break
                        }
                        rowData.push(
                            <ButtonSpeechBubble
                                key = {data.row.original.id + "_audio2text"}
                                size="sm"
                                className={"control-button-disabled"}
                                DisplayIcon={faTextWidth}
                                speechBubble={message}
                            />
                        );
                    } else if (!this.props.userInfo.options || (this.props.userInfo.options && !this.props.userInfo.options.audio2text)) {
                        rowData.push(
                            <ButtonSpeechBubble
                                key = {data.row.original.id + "_audio2text"}
                                size="sm"
                                className={"control-button"}
                                DisplayIcon={faTextWidth}
                                onClick={() => {
                                    let orgData = this.state.data;
                                    for (let i = 0; i < orgData.length; i++) {
                                        if (orgData[i].id === data.row.original.id) {
                                            orgData[i].ht87_voice_log_to_text = {status: "REQUEST_ACCEPTED"};
                                            this.ascAxios(
                                                'post',
                                                `${this.reactContainerPath}/exeAudio2TextByHt60Id`,
                                                {id: data.row.original.id}
                                            );
                                            this.setState({data: orgData});
                                            break;
                                        }
                                    }
                                }}
                                speechBubble={[
                                    <div>{this.props.langText.Body.audio2text}</div>
                                ]}
                            />
                        );
                    }

                    if (!this.props.currentPermission.download && !this.props.currentPermission.playback) {
                        return "-";
                    }
                } else if (data.row.original.voice_del_flag === "Y") {
                    let speechBubbleData = [];
                    if (data.row.original.voice_deleted) {
                        speechBubbleData.push(<div>{moment(data.row.original.voice_deleted).utc()
                            .add(9, "h").format("YYYY/MM/DD HH:mm")}</div>);
                    }
                    if (data.row.original.voice_deleted_user_id) {
                        speechBubbleData.push(<div>{data.row.original.voice_deleted_user_id
                            + " " + this.props.langText.Message.Record_VoiceDeleted}</div>)
                    }
                    rowData.push(<ButtonSpeechBubble
                        key = {data.row.original.id + "_voice_deleted"}
                        size="sm"
                        className="control-button-disabled"
                        DisplayIcon={faMinus}
                        speechBubble={speechBubbleData}
                    />);
                } else {}
                return rowData
                ;
            }
        })

        if (this.getLevelGreaterEqual(GlobalConst.LEVEL_OBJECT.company)) {
            let companyArray = [
                {
                    Header: this.props.langText.Body.UserId,
                    width: this.props.boardWidth.smedium,
                    accessor: "hm15_user.user_id",
                }
            ]
            return companyArray.concat(returnArray);
        } else {
            return returnArray;
        }
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();
        try{
            this.toggleBlocking();
            let res = await this.ascAxios(
                'post',
                `${this.reactContainerPath}/getS3PreSignedUrl`,
                {id: param.value}
            );
            switch (modalType) {
                case 'download':
                    window.location.href = res.data;
                    break;
                case 'playback':
                    let text = await this.ascAxios('post',
                        `Record/getVoiceLogToTextByHt60id`, {ht60_id: param.row.original.id});
                    this.setState({
                        id: param.row.original.id,
                        external_number: param.row.original.external_number,
                        dest_tel_number: param.row.original.dest_tel_number,
                        inout_type: param.row.original.inout_type,
                        answer_datetime: param.row.original.answer_datetime,
                        customer_id: param.row.original.hm01_customer_info
                            ? param.row.original.hm01_customer_info.id : "",
                        customer_name: param.row.original.hm01_customer_info
                            ? param.row.original.hm01_customer_info.name : "",
                        url: res.data,
                        ht87_voice_log_to_text: text && text.data && text.data.ht87_voice_log_to_text
                            && text.data.ht87_voice_log_to_text,
                        show: true,
                        modalType
                    });
                    break;
                default:
                    this.showErrorObjectMessage({message: 'modal'});
                    break;
            }
            this.toggleBlocking();
        } catch (e) {
            this.showErrorObjectMessage(e);
            this.toggleBlocking();
        }

    }

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "playback":
                return (
                    <RecordPlaybackModal
                        state = {this.state}
                        reactTableRefresh = {this.reactTableRefresh}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}
                        showErrorObjectMessage = {this.showErrorObjectMessage}
                        options={this.props.userInfo.options}
                        currentPermission={this.props.currentPermission}
                        secToTime={this.secToTime}
                        ascAxios = {this.ascAxios}/>
                );
            default:
                break;
        }
    }

    onClickHandle = async modalType => {
        switch (modalType) {
            case "playback":
                this.blockUI();
                await new Promise(resolve => setTimeout(resolve, 5000));
                this.setState({show: false});
                break;
            case "delete":
                // this.setState({show: false});
                break;
            default:
                this.showErrorObjectMessage({message: 'ModalTypeError'});
                break;
        }
    }

    validationHandle = param => {
        let validation_flag = false;
        switch (param) {
            case "playback":
                validation_flag = true;
                break;
            default:
                break;
        }
        return validation_flag;
    }

    /**
     * 必要な検索条件が入っているか確認
     */
    checkFilterValid = () => {
        let search_message = "";
        if(
            !this.getFilterData("user_id")
            && !this.getFilterData("answer_datetime")
            && !this.getFilterData("end_datetime")
            && !this.getFilterData("external_number")
            && !this.getFilterData("dest_tel_number")
        ){
            search_message = this.getLevelGreaterEqual(GlobalConst.LEVEL_OBJECT.company)
            ? this.props.langText.Message.Validation_Record_System
            : this.props.langText.Message.Validation_Record_Common;
            this.setState({
                search_flag: false,
                search_message
            });
        } else if(this.getFilterData("answer_datetime") && this.getFilterData("end_datetime")
            && (this.getFilterData("end_datetime") - this.getFilterData("answer_datetime")) <= 0){
            search_message = this.props.langText.Message.Validation_DateTime;
            this.setState({
                search_flag: false,
                search_message
            });
        } else {
            this.setState({
                search_flag: true,
                search_message
            });
        }
    }

    onChange = async (event, param) => {
        let my_filtered = [...this.state.hidden_filtered],
            target_index = my_filtered.findIndex(row => row.id === param),
            value = "";
        // セレクトボックス用。event無い場合はvalue空のまま
        if (event) {
            value = event.target ? event.target.value : event;
        }

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }

        if (value && (!Array.isArray(value) || value.length)) {
            if ((param === "end_datetime" || param === "answer_datetime")
                && ((!this.getFilterData(param) || (this.getFilterData(param)
                    && (moment(this.getFilterData(param)).year() !== moment(value).year()
                        || moment(this.getFilterData(param)).month() !== moment(value).month()
                        || moment(this.getFilterData(param)).day() !== moment(value).day()))))) {
                value.setHours(param === "answer_datetime" ? 0 : 23, param === "answer_datetime" ? 0 : 59)
            }
            my_filtered.push({
                id: param,
                value
            });


            if (param === "inout_type" && value === "all"){
                my_filtered = my_filtered.filter((elm)=> elm.param !== "inout_type" && elm.value !== "all")
            }
            if (param === "answer_datetime" && !this.getFilterData("end_datetime")) {
                let cp = new Date(value);
                cp.setHours(23,59);
                my_filtered.push({
                    id: "end_datetime",
                    value: cp
                });
            } else if (param === "end_datetime" && !this.getFilterData("answer_datetime")) {
                let cp = new Date(value);
                cp.setHours(0,0);
                my_filtered.push({
                    id: "answer_datetime",
                    value: cp
                });
            }
        } else if (!event && (param === "answer_datetime" || param === "end_datetime")) {
            my_filtered = my_filtered.filter(row => {
                return row.id !== "answer_datetime" && row.id !== "end_datetime";
            })
        }

        await this.setState({hidden_filtered: my_filtered});
        this.checkFilterValid();
    }

    getFilterData(param) {
        let my_param_data = this.state.hidden_filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    onSearch = async () => {
        await this.setState({filtered: this.state.hidden_filtered});
        await this.reactTableRefresh();
    }

    async getAudio2TextUsageMonth() {
        let result = await this.ascAxios('post', `${this.reactContainerPath}/getAudio2TextUsageMonth`, {});
        if (result && result.data && result.data.audio2text_usage_month >= 0) {
            await this.setState({audio2text_usage_month: result.data.audio2text_usage_month});
        }
    }

    render() {
        return (
            <div className="Record">
                <Row>
                    <div className="record-message">
                        {this.state.search_message}
                    </div>
                </Row>
                <Row>
                    <Col sm={12} md={2} lg={1}>
                        <div className="margin-top-05">{this.props.langText.Body.DateTime}</div>
                    </Col>
                    <Col xs={5} sm={5} md={3} lg={2} className = "date-picker-frame">
                        <DatePicker
                            isClearable = {true}
                            onChange = {e => this.onChange(e, "answer_datetime")}
                            selected = {this.getFilterData("answer_datetime")? this.getFilterData("answer_datetime"): null}
                            className = "date-picker form-control"
                            maxDate={!this.getFilterData("end_datetime")
                                ? new Date()
                                : this.getFilterData("end_datetime")}
                            minDate={!this.getFilterData("end_datetime")
                                ? subMonths(new Date(), 60)
                                : subDays(subMonths(this.getFilterData("end_datetime"), 1), -1)}
                            locale = "ja"
                            todayButton = {this.props.langText.Body.Today}
                            placeholderText = {this.props.langText.Body.StartDateTime}
                            dropdownMode="select"
                            timeInputLabel={this.props.langText.Body.TimeMin}
                            dateFormat="yyyy/MM/dd HH:mm"
                            showTimeInput
                            shouldCloseOnSelect={false}
                        />
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}>
                        <div className="margin-top-05">~</div>
                    </Col>
                    <Col xs={5} sm={5} md={3} lg={2} className = "date-picker-frame">
                    <DatePicker
                            isClearable = {true}
                            onChange = {e => this.onChange(e, "end_datetime")}
                            selected = {this.getFilterData("end_datetime")? this.getFilterData("end_datetime"): null}
                            className = "date-picker form-control"
                            maxDate={subMonths(this.getFilterData("answer_datetime"), -1) > new Date()
                                ? new Date()
                                : subDays(subMonths(this.getFilterData("answer_datetime"), -1), 1)}
                            minDate={this.getFilterData("answer_datetime")}
                            locale = "ja"
                            todayButton = {this.props.langText.Body.Today}
                            placeholderText = {this.props.langText.Body.EndDateTime}
                            dropdownMode="select"
                            timeInputLabel={this.props.langText.Body.TimeMin}
                            dateFormat="yyyy/MM/dd HH:mm"
                            showTimeInput
                            shouldCloseOnSelect={false}
                        />
                    </Col>
                </Row>
                <Row className="margin-top-03">
                <Col sm={4} md={2} lg={1}
                    hidden={!this.getLevelGreaterEqual(GlobalConst.LEVEL_OBJECT.company)}
                >
                    <div className="margin-top-05">{this.props.langText.Body.UserId}</div>
                </Col>
                <Col sm={12} md={4} lg={2}
                    hidden={!this.getLevelGreaterEqual(GlobalConst.LEVEL_OBJECT.company)}
                >
                    <FormControl
                        onChange = {
                            e => this.onChange(e, "user_id")
                        }
                        value = {this.getFilterData("user_id")}
                    />
                </Col>
                    <Col sm={4} md={2} lg={1}>
                        <div className="margin-top-05">{this.props.langText.Body.ExternalNumber}</div>
                    </Col>
                    <Col sm={12} md={4} lg={2}>
                        <FormControl
                            onChange = {
                                e => this.onChange(e, "external_number")
                            }
                            value = {this.getFilterData("external_number")}
                        />
                    </Col>
                    <Col sm={4} md={2} lg={1}>
                        <div className="margin-top-05">{this.props.langText.Body.DestTelNumber}</div>
                    </Col>
                    <Col sm={12} md={4} lg={2}>
                        <FormControl
                            onChange = {
                                e => this.onChange(e, "dest_tel_number")
                            }
                            value = {this.getFilterData("dest_tel_number")}
                        />
                    </Col>
                    <Col sm={4} md={2} lg={1}
                         hidden={!(this.props.userInfo.options
                             && this.props.userInfo.options.customer_infos)}>
                        <div className="margin-top-05">{this.props.langText.Body.CustomerName}</div>
                    </Col>
                    <Col sm={12} md={4} lg={2}
                         hidden={!(this.props.userInfo.options
                             && this.props.userInfo.options.customer_infos)}>
                        <FormControl
                            onChange = {
                                e => this.onChange(e, "hm01_customer_infos.name")
                            }
                            value = {this.getFilterData("hm01_customer_infos.name")}
                        />
                    </Col>
                    <Col sm={4} md={2} lg={1}>
                        <div className="margin-top-05">{this.props.langText.Body.InOutType}</div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={2}>
                        <ButtonGroup className = "record-btn-grp">
                            <Button
                                className = {`record-inout-btn ${this.state.hidden_filtered.some(row => row.id === "inout_type") ? "" : "record-inout-btn-focus"}`}
                                value = "all"
                                onClick = {e => this.onChange(e, "inout_type")}
                            >
                                {this.props.langText.Body.AllType}
                            </Button>
                            <Button
                                className = {`record-inout-btn ${this.state.hidden_filtered.some(row => row.id === "inout_type" && row.value === "in") ? "record-inout-btn-focus" : ""}`}
                                value = "in"
                                onClick = {e => this.onChange(e, "inout_type")}
                            >
                                {this.props.langText.Body.Incoming}
                            </Button>
                            <Button
                                className = {`record-inout-btn ${this.state.hidden_filtered.some(row => row.id === "inout_type" && row.value === "out") ? "record-inout-btn-focus" : ""}`}
                                value = "out"
                                onClick = {e => this.onChange(e, "inout_type")}
                            >
                                {this.props.langText.Body.Outgoing}
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs={6} sm={6} md={2} lg={1}>
                        <Button
                            id="record-search"
                            className="searchBtn"
                            disabled={!this.state.search_flag}
                            onClick={e => {
                                this.onSearch(e);
                                this.getAudio2TextUsageMonth();
                            }}>
                            {this.props.langText.Body.Search}
                        </Button>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={2}>
                        <div className="record-message-count">
                            {!this.state.filtered.some(row => row.id === "answer_datetime") ? "最新" : null}
                            {this.state.count ? this.numberWithCommas(this.state.count) : 0}
                            {this.props.langText.Body.Count}
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} className="margin-top-05">
                        <SetDataWithSpeechBubble
                            key = "audio2text-info"
                            className = {this.state.audio2text_usage_month > GlobalConst.VALUES.audio2text_free_hour? "record-message": ""}
                            displayData = {this.sprintf(
                                this.props.langText.Body.audio2textFreeHoursMessage,
                                this.state.audio2text_usage_month,
                                GlobalConst.VALUES.audio2text_free_hour)}
                            speechBubbleData = {[
                                <div key = "audio2textFreeHours">
                                    {this.sprintf(
                                    this.props.langText.Body.audio2textFreeHours,
                                    GlobalConst.VALUES.audio2text_free_hour)}
                                </div>,
                                <div key = "audio2textCallTimeToAccountMenu">
                                    {this.sprintf(
                                    this.props.langText.Body.audio2textCallTimeToAccountMenu,
                                    GlobalConst.VALUES.audio2text_inv_hour,
                                    GlobalConst.VALUES.audio2text_inv_yen)}
                                </div>
                            ]}
                            infoIconDisplayFlag = {true}
                            infoIconType = {faIcon.faInfoCircle}
                            infoIconClassName = "modal-info-icon"
                            infoIconColor = "orange"
                            infoIconSize = "lg"/>
                    </Col>
                </Row>
                <Row>
                    <>
                    {
                    this.state.blocking &&
                        <div className="asc-block-ui-record">
                            <div className="asc-block-icon-box">
                                <ReactLoading className="asc-block-icon" type="spinningBubbles" color="#b8b8b8" />
                                <label className="asc-block-label">{this.props.langText.Body.Wait}</label>
                            </div>
                        </div>
                    }
                    <Col xs={12} md={12} className = " margin-top-05">
                        <CommonTable
                            showPageSize={true}
                            showCheckbox={false}
                            showPagination={true}
                            manual = {true}
                            state = {this.state}
                            tableHeight={this.props.tableHeight-90}
                            tbodyHeight={this.props.tableHeight-70}
                            columns={this.state.columns}
                            filtered={this.state.filtered}
                            sorted={this.state.sorted}
                            pageCount = {this.state.pageCount}
                            propsPageIndex = {this.state.pageIndex? this.state.pageIndex: 0}
                            data={this.state.data}
                            loading={this.state.loading}
                            onFetchData={this.fetchData}
                            langText = {this.props.langText.Table}
                        />
                    </Col>
                    </>
                </Row>
                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </div>
        );
    }
}
